var isMobile = false;

$(window).resize(function () {
    var isWindowMobile = $(window).width() < 992 && $('.navbar-toggler').is(':visible');

    if (!isMobile && isWindowMobile) {
        window.location.reload();
    }
})
$(document).ready(function () {
    isMobile = $(window).width() < 992 && $('.navbar-toggler').is(':visible');

    $('img.svg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });


    if (!isMobile) {
        $(document).on('mouseover', '.box_single .tile', function (e) {
            $(this).siblings().addClass('not-hovering');
            $(this).parent().addClass('maximized').siblings().addClass('minimized');
        });

        $('.box_single').on('mouseout', function () {
            $('.box_single .tile').removeClass('not-hovering');
            $('.box_single').removeClass('minimized').removeClass('maximized');
        });
    }

    // $('.m-slider-switch a').eq(0).click();

    // Get started!
    if (isMobile) {
        $('.tiles-w-bg .tile.empty').remove();
        $(document).find('.tiles-w-bg .tile').unwrap();
        $('.tiles-w-bg').slick({
            arrows: false,
            dots: true,
        });
        $('.tiles-wo-bg').slick({
            arrows: false,
            dots: true,
        });
        $('.blog-tiles').slick({
            arrows: false,
            dots: true,
        });
        $('.pricing-plan-wrapper').slick({
            arrows: true,
            dots: true,
            initialSlide: 1,
            infinite: false,
            nextArrow: '<div class="arrow"></div>',
            prevArrow: '<div class="arrow"></div>'
        });
        $('.tiles-wo-bg-switch a').on('click', function () {
            setTimeout(function () {
                $('.tiles-wo-bg:visible').slick('setPosition');
            }, 200)
        });
    } else {

        $('.pricing-plan-wrapper').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: false,
            nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
            prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
            centerMode: true,
            centerPadding: 0
        });
        if ($('.pricing-plan-wrapper .slick-slide').length > 3)
            $('.pricing-plan-wrapper').slick('slickGoTo', 1);
    }

    $('.m-slider-switch a').on('click', function () {
        setTimeout(function () {
            $('.m-slider .content-wrapper:visible').slick('setPosition');
            $('.m-slider .video-slides:visible').slick('setPosition');
            $('.main_three_reasons_appending').html($('.methodologies-tab:visible .main_three_reasons').html());
        }, 200);
        $('.video-slider-count').text($(this).data('id'));
    });

    $('.books-slider-content').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        infinite: false,
        appendArrows: '#arrows-books-slider',
        asNavFor: '.books-slider-tiles',
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>'
    });
    $('.books-slider-tiles').slick({
        slidesToShow: (isMobile) ? 1 : 3,
        slidesToScroll: 1,
        asNavFor: '.books-slider-content',
        dots: isMobile,
        arrows: false,
        centerMode: false,
        infinite: false,
        focusOnSelect: true
    });

    $('#strategy-slider .content').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '#strategy-slider .images',
    });
    $('#strategy-slider .images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        asNavFor: '#strategy-slider .content',
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>'
    });

    $('.m-slider .content-wrapper').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        fade: true,
        asNavFor: '.m-slider .video-slides',
    });
    $('.m-slider .video-slides').slick({
        slidesToShow: (isMobile) ? 1 : 2,
        //centerMode: true,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.m-slider .content-wrapper',
        focusOnSelect: true,
        // appendArrows: '.m-slider-arrows',
        // nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        // prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>'
    });

    $('.faq-tiles .more-btn').click(function () {
        $(this).parent().toggleClass('active');
    });

    $('.m-slider-switch li').click(function () {
        $('.m-slider-arrows button').removeClass('inactive');
        var index = $(this).index();
        var val = (index * $('.first-m-slider-switch').height());
        $('.m-slider-switch').attr('style', 'top:-' + val + 'px');
        if (index === $('.m-slider-switch li').length - 1) {
            $('.m-slider-arrows .slick-next').addClass('inactive');
        }
        if (index === 0) {
            $('.m-slider-arrows .slick-prev').addClass('inactive');
        }
    })

    $('.show-full-content').click(function () {
        $('.full-content[data-fc-id="' + $(this).data('fc-ref-id') + '"]').show().siblings().hide();
        $('.m-slider').addClass('full-content-visible');
        return false;
    });

    $('.close-full-content').click(function () {
        $('.full-content').hide();
        $('.m-slider').removeClass('full-content-visible');
    });

    $('.m-slider-arrows .slick-next').click(function () {
        $('a.sections.active').parent().next().find('a')[0].click();
    })

    $('.m-slider-arrows .slick-prev').click(function () {
        $('a.sections.active').parent().prev().find('a')[0].click();
    })

    $('.explanation-slider').slick({
        arrows: true,
        dots: false,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
    });

    $('.podcast-video-slider').slick({
        arrows: true,
        dots: false,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
    });

    $('.sharing-slider').slick({
        arrows: true,
        dots: false,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
    });

    $('#free-trial-btn').click(function () {
        $('html, body').animate({
            scrollTop: $('#pricing-plans').position().top - $('.navbar').height()
        }, 1500)
    })

    $('.e-c-slider .slider').slick({
        arrows: true,
        dots: true,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
    });

    $('.video-grid-slider').slick({
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow:  (isMobile) ? 2 : 5,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><span class="arrow is-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="arrow is-left"></span></button>',
    });

    $('.training-list .show-content').click(function () {
        $(this).parent().toggleClass('active');
        return false;
    })
    $('.login-btn').click(function () {
        $('html').toggleClass('login-visible');
        return false;
    });

    $('.search-btn').click(function () {
        $('html').toggleClass('search-visible');
        return false;
    });

    $('#close-search').click(function () {
        $('html').removeClass('search-visible');
        return false;
    });

    $(document).mouseup(function (e) {
        var container = $("#login .container");
        if ($('html').hasClass('login-visible') && !container.is(e.target) && container.has(e.target).length === 0) {
            $('html').toggleClass('login-visible');
        }
    });
});


$(window).scroll(function () {
    if (!$('body').hasClass('page-template-no-sticky-header')) {
        if ($(this).scrollTop() > 150) {
            $('.navbar').addClass('fixed');
        } else {
            $('.navbar').removeClass('fixed');
        }
    }
})